<button
  class="border bg-white border-bn-button-border rounded-full flex justify-center items-center hover:brightness-[95%] aspect-square h-full"
  matRipple
  (click)="onShare()"
  aria-label="teilen">
  <mat-icon
    class="material-symbols-rounded text-fill-bn-chat-secondary-grey text-bn-chat-secondary-grey"
    >share</mat-icon
  >
</button>
