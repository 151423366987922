<form [formGroup]="form" class="grid grid-cols-12">
  <div class="flex flex-col gap-4 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Berufserfahrung</h1>

    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <span class="font-bold text-base pb-4">Aufgaben und Tätigkeiten</span>
        <mat-form-field>
          <mat-label>Berufsbezeichnung</mat-label>
          <input matInput formControlName="jobTitle" />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Beschäftigungsart</mat-label>
          <mat-select formControlName="employmentType">
            @for (type of employmentTypes; track $index) {
              <mat-option [value]="type">
                {{ type }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Pensum</mat-label>
          <input matInput formControlName="employmnentRateInPercantage" />
          <span class="pr-4" matSuffix>%</span>
        </mat-form-field>
      </div>

      <div class="flex flex-col">
        <span class="font-bold text-base pb-4">
          Ergänzungen (nur bei Unterrichtstätigkeit)
        </span>
        <mat-form-field appearance="fill">
          <mat-label>Fächer</mat-label>
          <input
            type="text"
            aria-label="Fächer"
            matInput
            [formControl]="subjectControl"
            [matAutocomplete]="auto" />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            (optionSelected)="onSelectSubject($event)">
            @for (subject of filteredSubjects$ | async; track subject) {
              <mat-option [value]="subject">
                {{ subject }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>

        <div class="flex gap-2 pb-8">
          @for (subject of selectedSubjects; track subject) {
            <mat-chip-row (removed)="removeSubject(subject)" class="optimize">
              {{ subject }}
              <button matChipRemove>
                <mat-icon class="material-symbols-rounded">cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
        </div>

        <mat-form-field appearance="fill">
          <mat-label>Schulstufen</mat-label>
          <mat-select formControlName="schoolLevels" multiple>
            @for (level of schoolLevels; track $index) {
              <mat-option [value]="level">
                {{ level }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Stufenspezifikationen</mat-label>
          <mat-select formControlName="schoolLevelDescriptions" multiple>
            @for (description of schoolLevelDescriptions; track $index) {
              <mat-option [value]="description">
                {{ description }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex flex-col">
        <span class="font-bold text-base pb-4">
          Schule | Organisation | Unternehmen
        </span>
        <mat-form-field>
          <mat-label>Name Arbeitgeber</mat-label>
          <input matInput formControlName="employer" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Ort</mat-label>
          <input matInput formControlName="location" />
        </mat-form-field>
      </div>
      <div class="flex flex-col">
        <span class="font-bold text-base pb-4">Zeitraum</span>
        <mat-form-field appearance="fill">
          <mat-label>Startdatum</mat-label>
          <input
            matInput
            appYearMonthFormat
            [matDatepicker]="startDatePicker"
            formControlName="startDate" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker
            #startDatePicker
            startView="multi-year"
            (monthSelected)="
              setMonthAndYear($event, startDatePicker, form.controls.startDate)
            "></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Enddatum</mat-label>
          <input
            matInput
            appYearMonthFormat
            [matDatepicker]="endDatePicker"
            formControlName="endDate" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker
            #endDatePicker
            startView="multi-year"
            (monthSelected)="
              setMonthAndYear($event, endDatePicker, form.controls.endDate)
            "></mat-datepicker>
        </mat-form-field>

        <mat-checkbox formControlName="isWorking" color="primary">
          <span class="text-base">
            Ich bin derzeit in dieser Position tätig
          </span>
        </mat-checkbox>

        <div class="flex flex-col pt-8">
          <span class="font-bold text-base pb-4">Beschreibung</span>
          <mat-form-field>
            <textarea
              matInput
              rows="8"
              formControlName="description"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      @if (showDeleteButton) {
        <button
          class="hidden md:block"
          mat-button
          color="warn"
          (click)="remove()">
          Löschen
        </button>
      }
      @if (showDeleteButton) {
        <mat-icon
          class="md:hidden material-symbols-rounded"
          mat-button
          color="warn"
          (click)="remove()">
          delete
        </mat-icon>
      }
      <div class="flex gap-8">
        <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
        <button
          [disabled]="form.invalid"
          mat-flat-button
          color="primary"
          (click)="save()">
          Speichern
        </button>
      </div>
    </div>
  </div>
</form>
