import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-empty-card',
  templateUrl: './empty-card.component.html',
  styleUrls: ['./empty-card.component.scss'],
})
export class EmptyCardComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() description?: string;
  @Input() link?: string;
  @Input() loading = false;
  @Input() icon = 'add';
  @Input() buttonText = 'Hinzufügen';

  @Output() triggerAction = new EventEmitter<void>();

  onAction() {
    this.triggerAction.emit();
  }

  getIcon() {
    return this.loading ? 'progress_activity' : this.icon;
  }
}
