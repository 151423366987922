<strong class="font-bold text-4xl md:text-5xl"> Arbeitstage </strong>

<div
  class="rounded-2xl p-5 md:p-10 mt-5 text-sm md:text-base"
  [ngClass]="{
    'bg-[#E1EDFF] text-[#001A41]': job.isFullTimeJob,
    'bg-[#F5DFE1] text-[#3B0716]': !job.isFullTimeJob
  }">
  <app-workload-matrix
    [isFullTimeJob]="job.isFullTimeJob"
    [formControl]="workdayMatrix"></app-workload-matrix>
</div>
