import { Component, Input, OnInit } from '@angular/core';
import { Job } from '../../../../types/job';
import { getPublicFileUrl } from '../../../../core/helpers';

@Component({
  selector: 'app-job-media-card',
  standalone: false,
  templateUrl: './job-media-card.component.html',
  styleUrl: './job-media-card.component.scss',
})
export class JobMediaCardComponent implements OnInit {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;

  activeIndex = 0;
  prevButtonDisabled = true;
  nextButtonDisabled = false;

  get showEmptyCard() {
    return !this.job.media || this.job.media.length < 1;
  }

  ngOnInit(): void {
    this.setButtonState();
  }

  getTitle() {
    if (this.job.media) {
      return this.job.media[this.activeIndex]?.title || '';
    }

    return '';
  }

  getDescription() {
    if (this.job.media) {
      return this.job.media[this.activeIndex]?.description || '';
    }

    return '';
  }

  getFallbackImage() {
    return getPublicFileUrl('schools', this.job.schoolId, 'backgroundImage');
  }

  prev() {
    this.activeIndex--;
    this.setButtonState();
  }

  next() {
    this.activeIndex++;
    this.setButtonState();
  }

  private setButtonState() {
    this.prevButtonDisabled = this.activeIndex < 1;
    this.nextButtonDisabled =
      this.job.media?.length === 0 ||
      this.activeIndex >= (this.job.media?.length || 0) - 1;
  }
}
