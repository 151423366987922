import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-job-type-chip',
  templateUrl: './job-type-chip.component.html',
  styleUrl: './job-type-chip.component.scss',
})
export class JobTypeChipComponent {
  @Input({ required: true }) type!: 'fulltime' | 'substitute';
}
