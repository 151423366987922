<strong class="font-bold text-4xl md:text-5xl">{{ title }}</strong>
<div class="py-4">
  <span class="text-base font-very-bright-color">
    Dieses Detail ist zurzeit noch leer und wird anderen daher nicht angezeigt.
  </span>
</div>
<div
  class="flex flex-col rounded-2xl p-8 text-base gap-8 border-2 border-bn-grey-border">
  <div class="flex flex-col gap-2">
    <strong class="font-bold">{{ subtitle }}</strong>
    <span>{{ description }}</span>
  </div>

  @if (loading) {
    <button
      class="min-w-36 w-fit"
      color="primary"
      disableRipple
      mat-stroked-button
      [routerLink]="link">
      <div class="flex items-center gap-7">
        <i class="spinner"></i>
        <div class="pt-1">
          <span>{{ buttonText }}</span>
        </div>
      </div>
    </button>
  } @else {
    <button
      (click)="onAction()"
      class="min-w-36 w-fit"
      color="primary"
      mat-stroked-button
      [routerLink]="link">
      <div class="flex gap-2 items-center">
        <div class="pt-1">
          <mat-icon class="material-symbols-rounded">
            {{ getIcon() }}
          </mat-icon>
        </div>
        <span>{{ buttonText }}</span>
      </div>
    </button>
  }
</div>
