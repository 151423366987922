@if (job$ | async; as job) {
  <div class="flex justify-center">
    <div class="w-full max-w-[1120px] grid grid-cols-12">
      <div class="col-span-12 lg:col-span-10 lg:col-start-2">
        <div class="flex flex-col gap-16">
          <div class="flex flex-col gap-5">
            <app-job-top-bar
              [job]="job"
              [isOwner]="isOwner"
              [isPreview]="isPreview"
              (jobStatusChange)="reload()" />

            <app-job-header [job]="job" [isEditable]="isEditable(job)" />

            <mat-divider />

            @if (!job.isPublic && isEditable(job)) {
              <app-job-progress-card [isOwner]="isOwner" [job]="job" />
            }
          </div>

          @if (shouldRenderChild(!jobService.isWorkdayMatrixEmpty(job), job)) {
            <app-job-workload-matrix-form
              id="workload-matrix"
              [job]="job"
              [isEditable]="isEditable(job)"
              (changeWorkdayMatrix)="reload()" />
          }

          <app-job-media-card [job]="job" [isEditable]="isEditable(job)" />

          @if (shouldRenderChild(!!job.weOffer, job)) {
            <app-job-we-offer-card [job]="job" [isEditable]="isEditable(job)" />
          }

          @if (shouldRenderChild(!!job.yourTasks, job)) {
            <app-job-tasks-card [job]="job" [isEditable]="isEditable(job)" />
          }

          @if (shouldRenderChild(!!job.yourSkills, job)) {
            <app-job-skills-card [job]="job" [isEditable]="isEditable(job)" />
          }
        </div>
      </div>
    </div>
  </div>
}
