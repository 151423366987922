<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <div class="flex flex-col">
      <h1 class="font-bold text-4xl">Hintergrundbild</h1>
      <span class="text-base text-[#B5B6C1]">
        Mit einem guten Hintergrundbild hebst du dich von anderen ab. Das
        Hinzufügen eines Fotos zu deinem Profil macht deine professionelle
        Geschichte optisch ansprechender. Mit dem Auswahlfeld und Zoom wählst du
        deinen perfekten Bildausschnitt.
      </span>
    </div>
    <div class="flex flex-col gap-8">
      <input
        type="file"
        class="hidden"
        [accept]="acceptTypes"
        (change)="selectFile($event)"
        #fileUpload />
      @if (!base64Image) {
        <img alt="Vorschau" class="rounded-2xl" [src]="getImageUrl()" />
      } @else {
        <div
          class="flex justify-center items-center min-w-full h-auto relative aspect-[355/118]">
          <image-cropper
            alignImage="center"
            format="jpeg"
            backgroundColor="#fff"
            class="image-cropper"
            [class.hidden]="loading"
            [imageBase64]="base64Image"
            [maintainAspectRatio]="true"
            [aspectRatio]="355 / 118"
            (imageCropped)="imageCropped($event)"
            [resizeToWidth]="dimension.width"
            [resizeToHeight]="dimension.height"
            [imageQuality]="90"
            [onlyScaleDown]="true"
            [containWithinAspectRatio]="true"
            [allowMoveImage]="true"
            [(transform)]="transform"
            (imageLoaded)="loading = false" />
          @if (loading) {
            <mat-spinner [diameter]="30" />
          }
        </div>
      }
      <div class="flex flex-col xl:flex-row gap-4 items-center justify-between">
        <div class="flex gap-4 order-2 xl:order-1">
          <button
            mat-stroked-button
            color="primary"
            (click)="fileUpload.click()">
            <mat-icon class="material-symbols-rounded">upload</mat-icon>
            Foto wählen
          </button>
          @if (!base64Image) {
            <button
              mat-stroked-button
              color="primary"
              (click)="clear()"
              [disabled]="!media.displayUrl">
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
              Löschen
            </button>
          } @else {
            <button mat-stroked-button color="primary" (click)="reset()">
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
              Löschen
            </button>
          }
        </div>

        @if (base64Image) {
          <div class="flex order-1 xl:order-2">
            <button mat-icon-button color="grey" (click)="zoomOut()">
              <mat-icon class="material-symbols-rounded"> zoom_out </mat-icon>
            </button>
            <button mat-icon-button color="grey" (click)="zoomIn()">
              <mat-icon class="material-symbols-rounded"> zoom_in </mat-icon>
            </button>
          </div>
        }
      </div>

      <div class="flex items-center">
        <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
          info
        </mat-icon>
        <div class="text-base text-[#B5B6C1]">
          Wähle ein Foto, das deine Persönlichkeit darstellt. Es kann ein Bild
          sein, das einen besonderen Meilenstein in deiner Karriere hervorhebt
          oder deine Interessen betont.
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <app-action-button
        [disabled]="!base64Image && !deleted"
        (onClick)="save()"
        text="Speichern" />
    </div>
  </div>
</div>
