import { Component, inject, Input } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-button',
  standalone: false,
  templateUrl: './share-button.component.html',
  styleUrl: './share-button.component.scss',
})
export class ShareButtonComponent {
  @Input({ required: true }) title = '';
  @Input({ required: true }) text = '';
  @Input() url = window.location.href;

  canShare = navigator.share !== undefined;

  router = inject(Router);
  analytics = inject(Analytics);

  async onShare() {
    logEvent(this.analytics, 'share_link', { url: this.url });

    if (this.canShare) {
      await navigator.share({
        title: this.title,
        text: this.text,
        url: this.url,
      });
    }
  }
}
