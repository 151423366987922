import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Job, WorkdayMatrix } from '../../../../types/job';
import { JobService } from '../../../../services/job.service';
import { debounceTime } from 'rxjs';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-job-workload-matrix-form',
  templateUrl: './job-workload-matrix-form.component.html',
  styleUrls: ['./job-workload-matrix-form.component.scss'],
})
export class JobWorkloadMatrixFormComponent implements OnInit, OnChanges {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isEditable!: boolean;
  @Output() changeWorkdayMatrix = new EventEmitter<WorkdayMatrix>();

  workdayMatrix = new FormControl<WorkdayMatrix>([], []);

  constructor(
    private jobService: JobService,
    private notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isEditable']) {
      if (changes['isEditable'].currentValue) {
        this.workdayMatrix.enable({ emitEvent: false });
      } else {
        this.workdayMatrix.disable({ emitEvent: false });
      }
    }
  }

  ngOnInit(): void {
    this.workdayMatrix.setValue(this.job.workdayMatrix || []);

    this.workdayMatrix.valueChanges
      .pipe(debounceTime(300))
      .subscribe(workdayMatrix => {
        if (workdayMatrix) {
          this.jobService
            .update(this.job.id!, { workdayMatrix })
            .subscribe(_ => {
              this.changeWorkdayMatrix.emit(workdayMatrix);
              this.notificationService.success('Arbeitstage aktualisiert.');
            });
        }
      });
  }
}
