import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Application } from '../../../types/application';
import { ApplicationService } from '../../../services/application.service';
import { BucketFile } from '../../../types/core';
import { Profile } from '../../../types/profile';
import { ProfileService } from '../../../services/profile.service';
import { FileService } from '../../../services/files.service';
import { PermissionsService } from '../../../services/permissions.service';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { getPublicFileUrl } from '../../../core/helpers';
import { MatDialog } from '@angular/material/dialog';
import { RejectMessageDialogComponent } from '../reject-message-dialog/reject-message-dialog.component';

@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrls: ['./application-detail.component.scss'],
})
export class ApplicationDetailComponent {
  id?: string | null;
  loading = false;
  isSchoolAdmin = false;
  qualifications: BucketFile[] = [];
  application$?: Observable<Application>;
  profile?: Profile;

  constructor(
    route: ActivatedRoute,
    private applicationService: ApplicationService,
    private fileService: FileService,
    private router: Router,
    private dialog: MatDialog,
    profileService: ProfileService,
    permissionService: PermissionsService,
    breadcrumbService: BreadcrumbService
  ) {
    route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.application$ = applicationService.getById(this.id!).pipe(
        tap(async application => {
          this.isSchoolAdmin = permissionService.isOwner(
            application.job.schoolRootId!
          );
          this.qualifications = [
            ...(application.diplomas || []),
            ...(application.certificates || []),
            ...(application.qualifications || []),
          ];

          if (this.isSchoolAdmin && application.userId) {
            profileService
              .getById(application.userId)
              .pipe(
                tap(profile => {
                  this.profile = profile;

                  breadcrumbService.set([
                    { text: 'Meine Schule', link: 'my-schools' },
                    {
                      text: application.job.schoolName || '',
                      link: `schools/${application.job.schoolId}/jobs`,
                    },
                    {
                      text: application.job.function || 'Inserat',
                      link: `schools/${application.job.schoolId}/jobs/${application.job.id}/applicants`,
                    },
                    { text: `Bewerbung: ${profile.name}` },
                  ]);
                })
              )
              .subscribe();
          } else {
            breadcrumbService.set([
              { text: 'Meine Bewerbungen', link: 'applications/my' },
              { text: application.job.schoolName || '' },
            ]);
          }
        })
      );
    });
  }

  getAvatarRouterLink(application: Application) {
    if (this.isSchoolAdmin) {
      if (!application.isAnonymousUser) {
        return ['/profile', application.userId];
      }
      return undefined;
    } else {
      return ['/schools', application.job.schoolId];
    }
  }

  getAvatar(application: Application) {
    // User avatar
    if (this.isSchoolAdmin) {
      return getPublicFileUrl('profiles', application.userId!, 'avatar');
    }

    // School avatar
    return getPublicFileUrl('schools', application.job.schoolId!, 'avatar');
  }

  getSizeOfAvatar() {
    const width = window.innerWidth;

    if (width < 768) {
      return '60';
    }

    if (width < 1280) {
      return '80';
    }

    return '120';
  }

  getUserJobFunctions() {
    return this.profile?.jobFunctions?.map(f => f.name).join(', ');
  }

  async download(file: BucketFile) {
    const key = `applications/${this.id!}/${file.filename}`;
    await this.fileService.download(key, file.description);
  }

  reject(application: Application) {
    this.loading = true;
    this.dialog
      .open(RejectMessageDialogComponent, {
        maxWidth: 'calc(100vw - 16px)',
        width: '600px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((response: { confirm: boolean; text: string }) => {
        if (response?.confirm) {
          this.loading = true;
          this.applicationService
            .reject(application.id!, response.text)
            .subscribe(_ => {
              this.router.navigate([
                `/schools/${application.job.schoolId}/applications`,
              ]);
            });
        } else {
          this.loading = false;
        }
      });
  }
}
