@if (!showEmptyCard) {
  <div class="flex w-fit">
    <strong
      class="font-bold text-4xl md:text-5xl overflow-visible whitespace-nowrap">
      Das bieten wir
    </strong>
    @if (isEditable) {
      <app-edit-button class="pl-4" routerLink="edit/weoffer" />
    }
  </div>
  <app-rich-text-view
    class="pt-4"
    [html]="job.weOffer_html"
    tailwindCssLineClamp="line-clamp-[17]" />
}

@if (showEmptyCard) {
  <app-empty-card
    title="Das bieten wir"
    subtitle="Mut zum Perspektivenwechsel."
    description="Überlege was sich Bewerbende von ihrem zukünftigen Arbeitgeber wünschen. Wie sieht ein richtig attraktives Angebot aus Bewerbersicht aus und was davon kannst du bieten?"
    link="edit/weoffer" />
}
