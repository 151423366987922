<div class="flex flex-col col-span-12 gap-5">
  <div class="xl:hidden flex items-center justify-between">
    <app-job-type-chip [type]="job.isFullTimeJob ? 'fulltime' : 'substitute'" />
    @if (job.modifiedOn) {
      <app-passed-time-info [time]="job.modifiedOn" />
    }
  </div>

  <div class="flex justify-between">
    <div class="flex gap-5">
      <div
        class="shrink-0 border-2 border-[#A4A4C0] h-20 w-20 md:h-[120px] md:w-[120px] rounded-2xl bg-center bg-no-repeat bg-contain"
        [ngStyle]="{
          'background-image':
            'url(' +
            getAvatarUrl(job) +
            '), url(/assets/defaults/school_avatar.svg)'
        }"></div>
      <div class="flex flex-col gap-1 md:gap-3">
        <app-job-item-field
          [editable]="isEditable"
          [hasValue]="!!job.function"
          title="Berufsbezeichnung"
          link="edit/function">
          <span class="text-2xl md:text-4xl font-bold">{{ job.function }}</span>
        </app-job-item-field>
        <app-job-item-field
          [editable]="isEditable"
          [hasValue]="hasSchoolLevels(job)"
          title="Schulstufe"
          link="edit/schoollevels">
          <span class="text-base md:text-2xl font-bold">
            {{ getJobLevels(job) }}
          </span>
        </app-job-item-field>
        <app-job-item-field
          class="pb-2"
          [editable]="isEditable"
          [hasValue]="hasSubjects(job)"
          title="Unterrichtsfächer"
          link="edit/subjects">
          <span class="text-sm m-0">{{ job.subjects?.join(' | ') }}</span>
        </app-job-item-field>
      </div>
    </div>

    <div class="hidden xl:flex flex-col justify-between items-end">
      <app-job-type-chip
        [type]="job.isFullTimeJob ? 'fulltime' : 'substitute'" />
      @if (job.modifiedOn) {
        <app-passed-time-info [time]="job.modifiedOn" />
      }
    </div>
  </div>

  <div
    class="p-5 md:p-10 rounded-xl flex flex-col gap-5"
    [ngClass]="{
      'text-[#001A41] bg-[#E1EDFF]': job.isFullTimeJob,
      'text-[#3B0716] bg-[#F5DFE1]': !job.isFullTimeJob,
      'xl:flex-row xl:flex-wrap xl:gap-10': !isEditable
    }">
    <ng-container
      *ngTemplateOutlet="
        jobAttributeItem;
        context: {
          icon: 'location_on',
          text: getSchoolNameAndLocation(job)
        }
      ">
    </ng-container>

    <app-job-item-field
      [editable]="isEditable"
      [hasValue]="!!job.activityRangeInPercentage || !!job.activityRangeInHours"
      title="Pensum"
      link="edit/workload">
      <ng-container
        *ngTemplateOutlet="
          jobAttributeItem;
          context: {
            icon: 'schedule',
            text: getScheduleText(job)
          }
        ">
      </ng-container>
    </app-job-item-field>

    <app-job-item-field
      [editable]="isEditable"
      [hasValue]="hasRequirements(job)"
      title="Anforderung"
      link="edit/requirements">
      <ng-container
        *ngTemplateOutlet="
          jobAttributeItem;
          context: {
            icon: 'history_edu',
            text: getRequirementsText(job)
          }
        ">
      </ng-container>
    </app-job-item-field>

    @if (job.isFullTimeJob) {
      <app-job-item-field
        [editable]="isEditable"
        [hasValue]="!!job.durationFrom"
        title="Antrittsdatum"
        link="edit/date">
        <ng-container
          *ngTemplateOutlet="
            jobAttributeItem;
            context: {
              icon: 'event',
              text: 'Ab: ' + (job.durationFrom | date: 'dd.MM.yyyy')
            }
          ">
        </ng-container>
      </app-job-item-field>
    } @else {
      <app-job-item-field
        [editable]="isEditable"
        [hasValue]="!!job.durationTo || !!job.durationFrom"
        title="Zeitraum"
        link="edit/duration">
        <ng-container
          *ngTemplateOutlet="
            jobAttributeItem;
            context: {
              icon: 'event',
              text: getSubstitutionPeriodText(job)
            }
          ">
        </ng-container>
      </app-job-item-field>
    }

    <app-job-item-field
      [editable]="isEditable"
      [hasValue]="!!job.applicationEndDate"
      title="Bewerbungsfrist"
      link="edit/deadline">
      <ng-container
        *ngTemplateOutlet="
          jobAttributeItem;
          context: {
            icon: 'av_timer',
            text:
              'Bewerbungsfrist: ' +
              (job.applicationEndDate | date: 'dd.MM.yyyy')
          }
        ">
      </ng-container>
    </app-job-item-field>
  </div>

  @if (!isEditable) {
    <div class="flex gap-5">
      <a
        class="action-button grow"
        [routerLink]="['/schools', job.schoolId]"
        mat-stroked-button>
        Schulprofil
      </a>
      @if (showApplyButton(isAuthorized$ | async)) {
        <a
          [routerLink]="getApplyLink(isAuthorized$ | async)"
          class="flex gap-1 border-none grow"
          mat-flat-button
          color="primary">
          <mat-icon class="material-symbols-rounded"> mail </mat-icon>
          <span class="inline-block pr-2">Bewerben</span>
        </a>
      }
      <app-job-share-button class="shrink w-10" [job]="job" />
    </div>
  }
</div>

<ng-template #jobAttributeItem let-icon="icon" let-text="text">
  <div class="flex items-center gap-2">
    <mat-icon class="material-symbols-rounded"> {{ icon }}</mat-icon>
    <span>
      {{ text }}
    </span>
  </div>
</ng-template>
