import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { JobContextMenuItem } from 'src/app/components/job-card/job-card.component';
import { JobCloseDialogComponent } from 'src/app/pages/jobs/job-close-dialog/job-close-dialog.component';
import { JobService } from 'src/app/services/job.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Job } from 'src/app/types/job';

@Component({
  selector: 'app-hr-job-card',
  templateUrl: './hr-job-card.component.html',
  styleUrl: './hr-job-card.component.scss',
})
export class HrJobCardComponent implements OnInit {
  @Input({ required: true }) job!: Job;

  menuEntries: JobContextMenuItem[] = [];
  isProcessing = false;

  constructor(
    private router: Router,
    private jobService: JobService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.buildContextMenu();
  }

  getApplicationsText() {
    return this.job.countApplications === 1
      ? '1 Bewerbung'
      : `${this.job.countApplications} Bewerbungen`;
  }

  async onClickContextItem(itemId: string) {
    switch (itemId) {
      case 'preview':
        await this.handleClickView();
        break;
      case 'edit':
        await this.handleClickEdit();
        break;
      case 'useAsTemplate':
        this.handleClickUseAsTemplate();
        break;
      case 'archive':
        this.handleClickArchive();
        break;
      case 'delete':
        this.handleClickDelete();
        break;
    }
  }

  private buildContextMenu() {
    this.menuEntries.push({
      id: 'preview',
      icon: 'preview',
      text: this.isDraft() ? 'Vorschau' : 'Ansehen',
    });

    // Can only edit jobs that are not closed yet
    if (!this.job.isClosed) {
      this.menuEntries.push({
        id: 'edit',
        icon: 'border_color',
        text: 'Bearbeiten',
      });
    }

    this.menuEntries.push({
      id: 'useAsTemplate',
      icon: this.isDraft() ? 'file_copy' : 'draft',
      text: this.isDraft() ? 'Duplizieren' : 'Als Vorlage verwenden',
    });

    // Can only archive public jobs
    if (this.isPublic()) {
      this.menuEntries.push({
        id: 'archive',
        icon: 'archive',
        text: 'Archivieren',
      });
    }

    // Can only delete drafts
    if (this.isDraft()) {
      this.menuEntries.push({
        id: 'delete',
        icon: 'delete',
        text: 'Verwerfen',
      });
    }
  }

  private isDraft() {
    return !this.job.isPublic && !this.job.isClosed;
  }

  private isPublic() {
    return this.job.isPublic && !this.job.isClosed;
  }

  private async handleClickView() {
    let navExtras: NavigationExtras | undefined = undefined;

    // public and draft jobs
    if (this.isDraft() || this.isPublic()) {
      navExtras = { queryParams: { preview: 'hr-tool' } };
    }

    await this.router.navigate(['/jobs', this.job.id], navExtras);
  }

  private async handleClickEdit() {
    await this.router.navigate(['/jobs', this.job.id]);
  }

  private handleClickUseAsTemplate() {
    this.isProcessing = true;
    this.jobService.createFromTemplate(this.job.id!).subscribe(async newJob => {
      this.isProcessing = false;

      if (this.isDraft()) {
        this.notificationService.success('Entwurf wurde kopiert.');
      } else {
        await this.router.navigate(['/jobs', newJob.id]);
      }
    });
  }

  private handleClickArchive() {
    this.isProcessing = true;
    const dialogRef = this.dialog.open(JobCloseDialogComponent, {
      disableClose: true,
      width: '100vw',
      minWidth: '100vw',
      height: '100vh',
      minHeight: '100vh',
    });
    dialogRef
      .afterClosed()
      .subscribe((result: { confirm: boolean; reason?: string }) => {
        if (result.confirm) {
          this.jobService.close(this.job.id!, result.reason).subscribe(() => {
            this.isProcessing = false;
            this.notificationService.success('Inserat wurde archiviert.');
          });
        } else {
          this.isProcessing = false;
        }
      });
  }

  private handleClickDelete() {
    this.isProcessing = true;
    this.jobService.discard(this.job.id!).subscribe(() => {
      this.isProcessing = false;
      this.notificationService.success('Entwurf wurde gelöscht.');
    });
  }
}
