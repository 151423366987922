import { Component, Input } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';

@Component({
  selector: 'app-passed-time-info',
  templateUrl: './passed-time-info.component.html',
  styleUrl: './passed-time-info.component.scss',
})
export class PassedTimeInfoComponent {
  @Input() time?: string;
  @Input() type: 'small' | 'regular' = 'regular';

  getHumanizedTime() {
    if (this.time) {
      const d = Date.parse(this.time);
      const str = formatDistanceToNow(d, { addSuffix: true, locale: de });
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return '';
  }
}
