import { Component, computed, inject, input, model } from '@angular/core';
import { Job } from '../../../../types/job';
import {
  MediaVideoService,
  MediaVideoServiceActions,
} from '../../../../services/media-video.service';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-job-intro-card',
  templateUrl: './job-intro-card.component.html',
  styleUrl: './job-intro-card.component.scss',
})
export class JobIntroCardComponent {
  readonly job = model.required<Job>();
  readonly isEditable = input.required<boolean>();
  readonly showEmptyCard = computed(() => !this.job().video?.id);

  readonly mediaVideoService = inject(MediaVideoService);
  readonly dialog = inject(MatDialog);

  loading: boolean = false;
  buttonText: string = 'Hochladen';

  constructor() {
    this.mediaVideoService.uploadProgress$.subscribe(progress => {
      if (this.loading) {
        this.buttonText = this.mediaVideoService.getProgress(progress);
      }
    });
  }

  async upload(event: Event): Promise<void> {
    const jid = this.job().id;

    if (jid) {
      this.loading = true;
      const video = await this.mediaVideoService.upload(
        event,
        jid,
        MediaVideoServiceActions.JOB
      );
      this.job.update(job => ({ ...job, video }));
      this.loading = false;
    }
  }

  async remove(): Promise<void> {
    if (environment.disableVideoDeletion) {
      alert('Video deletion is disabled');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: this.mediaVideoService.data,
    });

    dialogRef.afterClosed().subscribe(async confirmed => {
      const job = this.job();

      if (confirmed && job.id && job.video?.id) {
        this.loading = true;
        this.buttonText = this.mediaVideoService.deleting;
        await this.mediaVideoService.remove(
          job.video.id,
          job.id,
          MediaVideoServiceActions.JOB
        );
        this.job.update(job => ({ ...job, video: null }));
        this.buttonText = 'Hochladen';
        this.loading = false;
      }
    });
  }
}
