@if (!showEmptyCard) {
  <strong class="font-bold text-4xl md:text-5xl">Über mich</strong>
  @if (isOwner) {
    <app-edit-button class="pl-4" routerLink="edit/about"></app-edit-button>
  }
  <app-rich-text-view
    class="pt-4"
    [html]="profile.about_html"
    tailwindCssLineClamp="line-clamp-6"></app-rich-text-view>
}
@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Über mich"
    subtitle="Was macht dich aus?"
    description="Stell dich hier kurz vor – sag wer du bist und heb hervor, was dich besonders macht."
    link="edit/about"></app-empty-card>
}
