import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Job } from '../../../../types/job';
import { JobService } from 'src/app/services/job.service';

@Component({
  selector: 'app-job-progress-card',
  templateUrl: './job-progress-card.component.html',
  styleUrls: ['./job-progress-card.component.scss'],
})
export class JobProgressCardComponent implements OnChanges {
  @Input({ required: true }) job!: Job;
  @Input() isOwner = false;

  // required
  hasFunction = false;
  hasSchoolLevels = false;
  hasWorkload = false;
  hasDuration = false;

  // optional
  hasSubjects = false;
  hasRequirements = false;
  hasApplicationStartDate = false;
  hasStartDate = false;
  applicationEndDate = false;
  hasVideo = false;
  hasWorkdayMatrix = false;
  hasImpressions = false;
  hasWeOffer = false;
  hasTasks = false;
  hasSkills = false;

  constructor(private jobService: JobService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['job']) {
      this.determineProgress();
    }
  }

  determineProgress() {
    // Required
    const baseCheck = this.jobService.checkRequiredFields(this.job);
    this.hasFunction = baseCheck.hasFunction;
    this.hasSchoolLevels = baseCheck.hasSchoolLevels;
    this.hasWorkload = baseCheck.hasWorkload;
    this.hasDuration = baseCheck.hasDuration;

    // Optional
    this.hasSubjects = !!this.job.subjects && this.job.subjects.length > 0;
    this.hasRequirements = !!this.job.requirements;
    this.applicationEndDate = !!this.job.applicationEndDate;
    this.hasApplicationStartDate = !!this.job.durationFromUnix;
    this.hasWorkdayMatrix =
      !!this.job.workdayMatrix &&
      !this.jobService.isWorkdayMatrixEmpty(this.job);
    this.hasImpressions = !!this.job.media && this.job.media.length > 0;
    this.hasWeOffer = !!this.job.weOffer;
    this.hasTasks = !!this.job.yourTasks;
    this.hasSkills = !!this.job.yourSkills;
  }
}
