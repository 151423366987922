@if (hasValue) {
  <div
    class="flex gap-4 items-center"
    [ngClass]="{ 'max-w-[250px] sm:max-w-[400px]': editable }">
    <div
      [ngClass]="{
        'flex-1 overflow-hidden text-ellipsis': editable
      }">
      <ng-content></ng-content>
    </div>
    @if (editable) {
      <app-edit-button
        class="w-fit shrink-0"
        [routerLink]="link"></app-edit-button>
    }
  </div>
}
@if (!hasValue && editable) {
  <a
    class="flex gap-2 items-center text-[#2880fe] text-sm hover:underline"
    [routerLink]="link">
    <mat-icon class="material-symbols-rounded">add</mat-icon>
    <span>{{ title }}</span>
  </a>
}
