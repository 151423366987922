import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WorkdayMatrix, WorkdayMatrixItem } from '../../../types/job';

@Component({
  selector: 'app-workload-matrix',
  templateUrl: './workload-matrix.component.html',
  styleUrls: ['./workload-matrix.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WorkloadMatrixComponent),
      multi: true,
    },
  ],
})
export class WorkloadMatrixComponent implements ControlValueAccessor {
  @Input() isFullTimeJob = true;

  public propagateChange: any = () => {};
  public propagateTouch: any = () => {};

  disabled = false;

  readonly defaultWorkdayMatrix = [
    { am: false, pm: false },
    { am: false, pm: false },
    { am: false, pm: false },
    { am: false, pm: false },
    { am: false, pm: false },
  ];

  days: WorkdayMatrix = this.defaultWorkdayMatrix;

  getDay(i: number) {
    return ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'][i];
  }

  getShortDay(i: number) {
    return ['Mo', 'Di', 'Mi', 'Do', 'Fr'][i];
  }

  select(event: any, day: WorkdayMatrixItem, half: 'am' | 'pm') {
    if (this.disabled) {
      event.preventDefault();
      return;
    }

    day[half] = !day[half];
    this.propagateTouch(true);
    this.propagateChange(this.days);
  }

  writeValue(values: WorkdayMatrixItem[]) {
    if (values && values.length > 0) {
      for (let i = 0; i < 5; i++) {
        this.days[i].am = values[i].am;
        this.days[i].pm = values[i].pm;
      }
    } else {
      this.propagateChange([...this.days]);
    }
  }

  registerOnChange(fn: (_: any) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: any) => void) {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
