@if (!showEmptyCard) {
  <strong class="font-bold text-4xl md:text-5xl">Das bringst du mit</strong>
  @if (isEditable) {
    <app-edit-button class="pl-4" routerLink="edit/skills" />
  }
  <app-rich-text-view
    class="pt-4"
    [html]="job.yourSkills_html"
    tailwindCssLineClamp="line-clamp-6" />
}

@if (showEmptyCard) {
  <app-empty-card
    title="Das bringst du mit"
    subtitle="Was erwartest du von deiner neuen Fachkraft?"
    description='Fachkenntnisse, Berufserfahrung und Qualifikationen. Hier schreibst du bspw., welche Anforderungen ein "must have" und welche "nice to have" sind.'
    link="edit/skills" />
}
