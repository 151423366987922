<div
  class="flex flex-col gap-8 border-dot rounded-[0.75rem] md:rounded-[1.35rem] p-10">
  <div>
    <h2 class="font-bold text-2xl">Finde die passende Fachkraft</h2>
    <span class="text-sm font-very-bright-color">
      Je mehr Zusatzangaben, desto höher die Chance, passende Fachkräfte
      anzusprechen. Dein Vorteil: Jedes Inserat ist mit dem Schulprofil
      verknüpft!
    </span>
  </div>
  <div class="flex flex-col xl:flex-row xl:justify-between">
    <div class="flex flex-col text-sm gap-2 pb-6 xl:pb-0">
      <strong class="text-base font-bold">Pflichtangaben</strong>
      <app-progress-card-item
        text="Berufsbezeichnung*"
        link="edit/function"
        [success]="hasFunction" />
      <app-progress-card-item
        text="Schulstufe*"
        link="edit/schoollevels"
        [success]="hasSchoolLevels" />
      <app-progress-card-item
        text="Pensum*"
        link="edit/workload"
        [success]="hasWorkload" />
      @if (job.isFullTimeJob) {
        <app-progress-card-item
          text="Antrittsdatum*"
          link="edit/date"
          [success]="hasDuration" />
      } @else {
        <app-progress-card-item
          text="Zeitraum*"
          link="edit/duration"
          [success]="hasDuration" />
      }
    </div>

    <div class="flex flex-col text-sm gap-2 pb-2 xl:pb-0">
      <strong class="text-base font-bold">Zusätzliche Angaben</strong>
      <app-progress-card-item
        text="Unterrichtsfächer"
        link="edit/subjects"
        [success]="hasRequirements" />
      <app-progress-card-item
        text="Anforderung"
        link="edit/requirements"
        [success]="hasRequirements" />
      @if (job.isFullTimeJob) {
        <app-progress-card-item
          text="Bewerbungsfrist"
          link="edit/deadline"
          [success]="applicationEndDate" />
      }
      <app-progress-card-item
        text="Video"
        link="edit/video"
        [success]="hasVideo" />
      <app-progress-card-item
        text="Arbeitstage"
        [success]="hasWorkdayMatrix"
        [scrollTargetId]="'workload-matrix'" />
    </div>

    <div class="flex flex-col text-sm gap-2">
      <strong class="hidden xl:block text-base font-bold">&nbsp;</strong>
      <app-progress-card-item
        text="Impressionen"
        link="edit/media"
        [success]="hasImpressions" />
      <app-progress-card-item
        text="Das bieten wir"
        link="edit/weoffer"
        [success]="hasWeOffer" />
      <app-progress-card-item
        text="Deine Aufgaben"
        link="edit/tasks"
        [success]="hasTasks" />
      <app-progress-card-item
        text="Das bringst du mit"
        link="edit/skills"
        [success]="hasSkills" />
    </div>
  </div>
  <div class="flex flex-col md:flex-row rounded-2xl p-8 bg-box text-base">
    * Diese Mindestangaben sind notwendig, damit du Stellen ausschreiben kannst.
  </div>
</div>
