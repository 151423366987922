<div class="flex justify-center">
  <div class="w-full max-w-[1120px]">
    @if (!isOwner) {
      <div class="pb-4">
        <a mat-button routerLink="/schools" queryParamsHandling="preserve">
          <mat-icon class="material-symbols-rounded"
            >keyboard_backspace</mat-icon
          >
          Alle Ergebnisse
        </a>
      </div>
    }

    @if (school$ | async; as school) {
      <div class="flex flex-col">
        <app-school-header [school]="school" [isOwner]="isOwner" />
        <div class="grid grid-cols-12">
          <div class="flex flex-col col-span-12">
            @if (!school.isPublic && isOwner) {
              <app-school-progress-card
                class="conditional-hide pt-8 md:pt-10"
                (publish)="publish(school)"
                [buttonDisabled]="missingFields.length > 0"
                [school]="school" />
            }

            <div class="flex flex-col gap-10 md:gap-24 pt-10 md:pt-24">
              <app-school-intro-card
                id="intro-card"
                class="conditional-hide"
                [school]="school"
                [isOwner]="isOwner" />

              <app-school-about-card
                class="conditional-hide"
                [school]="school"
                [isOwner]="isOwner" />

              <app-school-media-card
                class="conditional-hide"
                [school]="school"
                [isOwner]="isOwner" />

              <app-school-weoffer-card
                class="conditional-hide"
                [school]="school"
                [isOwner]="isOwner" />

              <app-school-focus-card
                class="conditional-hide"
                [school]="school"
                [isOwner]="isOwner" />

              <app-school-documents-card
                class="conditional-hide"
                [school]="school"
                [isOwner]="isOwner" />

              <app-school-contact-card
                class="conditional-hide"
                [school]="school"
                [isOwner]="isOwner" />

              <div
                class="border border-bn-grey-border only-school-job-card-exists w-full"></div>

              <app-school-jobs-card
                class="conditional-hide"
                [school]="school" />
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
