import { Injectable } from '@angular/core';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import {
  UploadProgressEvent,
  VideoUploadResponse,
  VideoUploader,
} from '@api.video/video-uploader';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { VideoUploadToken } from '../types/video';

@Injectable({
  providedIn: 'root',
})
export class ApiVideoService {
  readonly defaultProgressValue: UploadProgressEvent = {
    uploadedBytes: 0,
    totalBytes: 0,
    currentChunk: 0,
    chunksCount: 0,
    chunksBytes: 0,
    currentChunkUploadedBytes: 0,
  };
  private uploadProgressSubject = new BehaviorSubject<UploadProgressEvent>(
    this.defaultProgressValue
  );
  private createVideoToken: () => Observable<VideoUploadToken>;
  private deleteVideo: (data: {
    videoId: string;
    schoolId: string;
  }) => Observable<void>;
  private deleteJobVideo: (data: {
    videoId: string;
    jobId: string;
  }) => Observable<void>;

  uploadProgress$ = this.uploadProgressSubject.asObservable();

  constructor(functions: Functions) {
    this.createVideoToken = httpsCallableData(
      functions,
      'createvideotoken',
      {}
    );
    this.deleteVideo = httpsCallableData(functions, 'deletevideo', {});
    this.deleteJobVideo = httpsCallableData(functions, 'deletejobvideo', {});
  }

  async uploadAsync(file: File) {
    return new Promise<VideoUploadResponse>(async resolve => {
      // eslint-disable-next-line prefer-const
      let response: VideoUploadResponse;

      this.uploadProgressSubject.next(this.defaultProgressValue);
      const { token } = await lastValueFrom(this.createVideoToken());
      if (!token) {
        throw new Error('No token');
      }

      const uploader = new VideoUploader({
        uploadToken: token,
        file,
      });

      uploader.onProgress(e => {
        this.uploadProgressSubject.next(e);
      });

      uploader.onPlayable(() => {
        resolve(response);
      });

      response = await uploader.upload();
    });
  }

  remove(videoId: string, schoolId: string) {
    return this.deleteVideo({ videoId, schoolId });
  }

  removeJobVideo(videoId: string, jobId: string) {
    return this.deleteJobVideo({ videoId, jobId });
  }
}
