<div
  class="h-10 w-fit px-4 rounded-xl flex items-center gap-2"
  [ngClass]="{
    'bg-[#001A41]': type === 'fulltime',
    'text-[#ECF4FF]': type === 'fulltime',
    'bg-[#3B0716]': type === 'substitute',
    'text-[#F5DFE1]': type === 'substitute'
  }">
  <mat-icon class="material-symbols-rounded">
    {{ type === 'fulltime' ? 'work' : 'work_history' }}
  </mat-icon>
  <span>{{ type === 'fulltime' ? 'Feststelle' : 'Stellvertretung' }}</span>
</div>
