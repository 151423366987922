import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JobService } from 'src/app/services/job.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Job } from 'src/app/types/job';
import { JobCloseDialogComponent } from '../../job-close-dialog/job-close-dialog.component';

@Component({
  selector: 'app-job-top-bar',
  templateUrl: './job-top-bar.component.html',
  styleUrl: './job-top-bar.component.scss',
})
export class JobTopBarComponent implements OnChanges {
  @Input({ required: true }) job!: Job;
  @Input({ required: true }) isOwner!: boolean;
  @Input({ required: true }) isPreview!: boolean;
  @Output() jobStatusChange = new EventEmitter();

  actionInProgress:
    | 'closePreview'
    | 'publish'
    | 'preview'
    | 'discard'
    | 'archive'
    | null = null;
  previewOrigin: 'detail' | 'hr-tool' | undefined = undefined;
  canPublish = false;
  // Is necessary to bind the context to the functions
  ctxActions = {
    closePreview: async () => this.executeClosePreviewing(),
    preview: async () => this.executePreviewing(),
    discard: () => this.executeDiscarding(),
    archive: () => this.executeArchiving(),
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jobService: JobService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['job']) {
      const { hasFunction, hasSchoolLevels, hasWorkload, hasDuration } =
        this.jobService.checkRequiredFields(this.job);
      this.canPublish =
        hasFunction && hasSchoolLevels && hasWorkload && hasDuration;
    }

    if (changes['isPreview'] && changes['isPreview'].currentValue) {
      this.previewOrigin =
        this.route.snapshot.queryParamMap.get('preview') === 'hr-tool'
          ? 'hr-tool'
          : 'detail';
    }
  }

  isDraftJob() {
    return !this.job.isPublic && !this.job.isClosed;
  }

  isPublicJob() {
    return this.job.isPublic && !this.job.isClosed;
  }

  isArchivedJob() {
    return !this.job.isPublic && this.job.isClosed;
  }

  getReturnUrl() {
    return this.isOwner
      ? ['/my-schools', this.job.schoolRootId, 'jobs']
      : '/jobs';
  }

  async executePublishing() {
    this.actionInProgress = 'publish';
    this.jobService.publish(this.job.id!).subscribe(() => {
      this.jobStatusChange.emit();
      this.notificationService.success('Inserat veröffentlicht.');
      this.actionInProgress = null;
    });
  }

  async executePreviewing() {
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { preview: 'detail' },
      queryParamsHandling: 'merge',
    });
  }

  async executeClosePreviewing() {
    if (this.previewOrigin === 'hr-tool') {
      await this.router.navigate([
        '/my-schools',
        this.job.schoolRootId,
        'jobs',
      ]);
    } else {
      const currentParams = { ...this.route.snapshot.queryParams };
      delete currentParams['preview'];

      await this.router.navigate([], {
        relativeTo: this.route,
        queryParams: currentParams,
      });
    }
  }

  executeArchiving() {
    this.actionInProgress = 'archive';
    const dialogRef = this.dialog.open(JobCloseDialogComponent, {
      disableClose: true,
      width: '100vw',
      minWidth: '100vw',
      height: '100vh',
      minHeight: '100vh',
    });
    dialogRef
      .afterClosed()
      .subscribe((result: { confirm: boolean; reason?: string }) => {
        if (result.confirm) {
          this.jobService.close(this.job.id!, result.reason).subscribe(() => {
            this.jobStatusChange.emit();
            this.actionInProgress = null;
            this.notificationService.success('Inserat wurde archiviert.');
          });
        } else {
          this.actionInProgress = null;
        }
      });
  }

  executeDiscarding() {
    this.actionInProgress = 'discard';
    this.jobService.discard(this.job.id!).subscribe(() => {
      this.actionInProgress = null;
      this.router.navigate(['/my-schools', this.job.schoolRootId, 'jobs']);
      this.notificationService.success('Entwurf wurde gelöscht.');
    });
  }
}
