@if (!showEmptyCard) {
  <strong class="font-bold text-4xl md:text-5xl">Ausbildung</strong>
  @if (isOwner) {
    <app-add-button
      class="pl-4"
      routerLink="edit/education/new"></app-add-button>
  }
  <div class="flex flex-col pt-4 gap-8">
    @for (
      education of educations! | sortby: '-endDate' | slice: 0 : amount;
      track education.id;
      let i = $index
    ) {
      <div class="flex flex-row gap-8">
        <div
          [ngClass]="{ 'primary-bg': i === 0 }"
          class="w-[3px] rounded-full"></div>
        <div class="flex flex-col">
          <strong
            class="font-bold text-[0.625rem] font-very-bright-color tracking-widest">
            {{ getStartDate(education) }} - {{ getEndDate(education) }}
          </strong>
          <div class="min-h-12 flex">
            <strong class="font-bold text-base self-center">
              {{ education.course }}
            </strong>
            @if (isOwner) {
              <app-edit-button
                [background]="false"
                class="pl-2"
                [routerLink]="
                  'edit/education/' + education.id
                "></app-edit-button>
            }
          </div>
          <span class="text-sm">{{ education.degree }}</span>
          <div class="flex flex-col pt-2 pb-2">
            <span class="text-sm font-very-bright-color">
              {{ education.schoolName }}
            </span>
            <span class="text-sm font-very-bright-color">
              {{ education.location }}
            </span>
          </div>
        </div>
      </div>
    }
    <div class="flex justify-start">
      @if (showAll && profile.educations!.length > 3) {
        <button
          mat-stroked-button
          color="primary"
          class="mdc-button--light mdc-button--grey"
          (click)="toggleShowAll()">
          <mat-icon class="material-symbols-rounded scale-125">
            expand_less
          </mat-icon>
          Weniger anzeigen
        </button>
      }
      @if (!showAll && profile.educations!.length > 3) {
        <button
          mat-stroked-button
          color="primary"
          class="mdc-button--light mdc-button--grey"
          (click)="toggleShowAll()">
          <mat-icon class="material-symbols-rounded scale-125">
            expand_more
          </mat-icon>
          Weiterlesen
        </button>
      }
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Ausbildung"
    subtitle="Von der Schule zur Hochschule und zurück."
    description="Du kannst hier deine Abschlüsse hinzufügen."
    link="edit/education/new"></app-empty-card>
}
