<div class="grid grid-cols-12 pt-24 min-h-screen">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <strong class="text-base font-bold">
      Deine persönlichen Daten sind die Basis deines Bildigsnetz-Profils.
    </strong>
    <mat-stepper
      [@.disabled]="true"
      [scrollTop]="true"
      matStepperIcon="info"
      [linear]="true"
      labelPosition="bottom"
      #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon class="material-symbols-rounded scale-125">done</mat-icon>
      </ng-template>
      <mat-step [stepControl]="nameForm" label="Dein Name">
        <form class="flex flex-col pt-16 gap-4" [formGroup]="nameForm">
          <div class="flex flex-col">
            <h1 class="font-bold text-4xl">Dein Name</h1>
          </div>
          <mat-form-field>
            <mat-label>Vorname und Nachname</mat-label>
            <input matInput type="text" formControlName="name" />
          </mat-form-field>
          <div class="flex justify-end gap-8 pt-8">
            <app-secondary-button
              [disabled]="!nameForm.valid"
              (onClick)="stepper.next()">
              Weiter
            </app-secondary-button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="contactForm" label="Kontaktdaten">
        <form class="flex flex-col pt-16" [formGroup]="contactForm">
          <div class="flex flex-col pb-8">
            <h1 class="font-bold text-4xl">Kontaktdaten</h1>
            <span class="text-base text-[#B5B6C1]">
              Wir benötigen deine Kontaktdaten, damit Schulen dein Profil finden
              und dir passende Stellenangebote machen können.
            </span>
          </div>

          <mat-form-field>
            <mat-label>Postleitzahl</mat-label>
            <input
              matInput
              type="text"
              maxlength="4"
              formControlName="zipcode" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Ort</mat-label>
            <input matInput type="text" formControlName="city" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Telefonnummer</mat-label>
            <input matInput type="text" formControlName="phone" />
          </mat-form-field>
          <div class="flex justify-end gap-8 pt-8">
            <button (click)="stepper.previous()" mat-button color="primary">
              Zurück
            </button>
            <app-secondary-button
              [disabled]="!contactForm.valid"
              (onClick)="stepper.next()">
              Weiter
            </app-secondary-button>
          </div>
        </form>
      </mat-step>

      <mat-step label="Beruf" [stepControl]="selectedJobFunctionsControl">
        <div class="flex flex-col pt-16 gap-4">
          <div class="flex flex-col">
            <h1 class="font-bold text-4xl">Berufsbezeichnung</h1>
            <p class="text-base text-[#B5B6C1]">
              Gib hier all deine Bildungsjobs an, von denen du möchtest, dass
              sie prominent in deinem Profil erscheinen. Gib die
              Berufsbezeichnungen
              <span class="font-black">in der Reihenfolge</span>
              an, in welcher sie beim Profil-Header angezeigt werden sollen,
              angefangen beim obersten! Gib nur Jobs an, für die du qualifiziert
              bist oder für die du in Ausbildung bist.
            </p>
          </div>

          <div class="flex flex-col gap-14">
            <div class="flex">
              <mat-form-field appearance="fill" class="grow">
                <mat-label>Berufsbezeichnung</mat-label>
                <input
                  type="text"
                  placeholder="Schreibe hier rein!"
                  aria-label="Fächer"
                  matInput
                  [formControl]="jobFunctionControl"
                  [matAutocomplete]="auto"
                  (keyup.enter)="add()" />
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto="matAutocomplete"
                  (optionSelected)="onSelectJobFunction($event)">
                  @for (
                    jobFunction of filteredJobFunctions$ | async;
                    track jobFunction
                  ) {
                    <mat-option [value]="jobFunction">
                      {{ jobFunction }}
                    </mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
              <div class="pl-4 pt-2.5">
                <button
                  [disabled]="!jobFunctionControl.value"
                  mat-flat-button
                  color="primary"
                  (click)="add()">
                  Hinzufügen
                </button>
              </div>
            </div>

            @for (
              selectedJobFunction of selectedJobFunctions;
              track selectedJobFunction;
              let i = $index
            ) {
              <div class="flex flex-col gap-4 pt-16">
                <div class="flex grow items-center justify-between">
                  <span class="font-bold text-xl">
                    {{ selectedJobFunction.value }}
                  </span>
                  <button mat-icon-button (click)="remove(i)">
                    <mat-icon class="material-symbols-rounded">delete</mat-icon>
                  </button>
                </div>
                <div class="flex flex-col pb-4">
                  <mat-form-field>
                    <mat-label>
                      Stufen (falls für deine Berufsfunktion relevant)
                    </mat-label>
                    <mat-select
                      [formControl]="selectedJobFunction.jobLevelsControl"
                      multiple>
                      @for (level of schoolLevels; track $index) {
                        <mat-option [value]="level">
                          {{ level }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                  @if (showJobLevelDescriptionsField(selectedJobFunction)) {
                    <mat-form-field>
                      <mat-label>Stufenspezifikation</mat-label>
                      <mat-select
                        [formControl]="
                          selectedJobFunction.jobLevelDescriptionsControl
                        "
                        multiple>
                        @for (
                          description of schoolLevelDescriptions;
                          track $index
                        ) {
                          <mat-option [value]="description">
                            {{ description }}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  }
                  <div class="flex flex-col text-base pl-[18px]">
                    <div class="flex justify-between">
                      <span>In Ausbildung</span>
                      <mat-slide-toggle
                        color="primary"
                        [formControl]="
                          selectedJobFunction.inStudyControl
                        "></mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
            }

            <mat-divider />

            <div class="flex flex-col">
              <div class="flex flex-col">
                <h1 class="font-bold text-2xl">
                  Möchtest du direkte Angebote für Feststellen oder
                  Stellvertretungen bekommen?
                </h1>
                <p class="text-base text-[#B5B6C1]">
                  Deine Auswahl wird bei deinem Profil angezeigt.
                </p>
              </div>

              <div class="flex flex-col">
                <mat-checkbox
                  color="primary"
                  [formControl]="interestedInFulltimeJobsControl"
                  >Offen für Feststellen</mat-checkbox
                >
                <mat-checkbox
                  color="primary"
                  [formControl]="interestedInSubstituteJobsControl"
                  >Offen für Stellvertretungen</mat-checkbox
                >
              </div>
            </div>
          </div>

          <div class="flex justify-end gap-8 pt-8">
            <button (click)="stepper.previous()" mat-button color="primary">
              Zurück
            </button>
            <app-secondary-button
              [disabled]="selectedJobFunctions.length < 1"
              (onClick)="stepper.next()">
              Weiter
            </app-secondary-button>
          </div>
        </div>
      </mat-step>

      <mat-step label="Profilbild">
        <div class="flex flex-col pt-16 gap-4">
          <div class="flex flex-col">
            <h1 class="font-bold text-4xl">Profilbild</h1>
            <span class="text-base text-[#B5B6C1]">
              Kein professionelles Foto nötig! Verwende ein Bild, das zeigt, wer
              du bist.
            </span>
          </div>

          <input
            type="file"
            class="hidden"
            [accept]="acceptTypes"
            (change)="selectFile($event)"
            #fileUpload />
          <div class="flex flex-col">
            @if (!base64Image) {
              <div class="flex justify-center">
                <img
                  alt="Profile Picture"
                  class="rounded-full w-[220px] h-[220px]"
                  [src]="getImageUrl()" />
              </div>
            } @else {
              <div
                class="flex justify-center items-center min-w-full h-auto relative aspect-square">
                <image-cropper
                  alignImage="center"
                  format="jpeg"
                  backgroundColor="#fff"
                  class="image-cropper"
                  [class.hidden]="loadingImage"
                  [imageBase64]="base64Image"
                  [maintainAspectRatio]="true"
                  [aspectRatio]="1 / 1"
                  (imageCropped)="imageCropped($event)"
                  [imageQuality]="90"
                  [onlyScaleDown]="true"
                  [resizeToWidth]="dimension.width"
                  [resizeToHeight]="dimension.height"
                  [containWithinAspectRatio]="true"
                  [allowMoveImage]="true"
                  [roundCropper]="true"
                  [(transform)]="transform"
                  (imageLoaded)="loadingImage = false" />
                @if (loadingImage) {
                  <mat-spinner [diameter]="30" />
                }
              </div>
            }
          </div>
          <div
            class="flex flex-col xl:flex-row gap-4 items-center justify-between">
            <button
              mat-stroked-button
              color="primary"
              class="order-2 xl:order-1"
              (click)="fileUpload.click()">
              <mat-icon class="material-symbols-rounded">upload</mat-icon>
              Foto wählen
            </button>

            @if (base64Image) {
              <div class="flex order-1 xl:order-2">
                <button mat-icon-button color="grey" (click)="zoomOut()">
                  <mat-icon class="material-symbols-rounded">
                    zoom_out
                  </mat-icon>
                </button>
                <button mat-icon-button color="grey" (click)="zoomIn()">
                  <mat-icon class="material-symbols-rounded">
                    zoom_in
                  </mat-icon>
                </button>
              </div>
            }
          </div>

          <div class="flex items-center pb-8">
            <mat-icon class="text-[#FFB81C] material-symbols-rounded pr-8">
              info
            </mat-icon>
            <div class="text-base text-[#B5B6C1]">
              Mitglieder müssen auf Bildigsnetz ihre echten Identitäten nutzen.
              Nimm also ein Foto von dir auf oder lade ein vorhandenes Foto von
              dir hoch.
            </div>
          </div>

          <div class="flex justify-end gap-8 pt-8">
            <button (click)="stepper.previous()" mat-button color="primary">
              Zurück
            </button>
            <app-action-button
              [loading]="loading"
              (onClick)="save()"
              text="Loslegen"></app-action-button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
