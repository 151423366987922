import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-job-item-field',
  templateUrl: './job-item-field.component.html',
  styleUrls: ['./job-item-field.component.scss'],
})
export class JobItemFieldComponent {
  @Input() hasValue = false;
  @Input() editable = false;
  @Input() title = '';
  @Input({ required: true }) link!: string;
}
