@if (application$ | async; as application) {
  <div class="grid grid-cols-12">
    <div
      class="col-span-12 lg:col-span-8 lg:col-start-3 flex flex-col gap-8 xl:gap-20">
      <div class="flex flex-col gap-5 xl:gap-10">
        <div class="3xl:hidden flex justify-between items-center">
          @if (!isSchoolAdmin) {
            <app-job-type-chip
              [type]="
                application.job.isFullTimeJob ? 'fulltime' : 'substitute'
              ">
            </app-job-type-chip>
          }
          <app-passed-time-info [time]="application.createdOn" />
        </div>

        <div class="flex justify-between items-center">
          <app-round-image
            class="cursor-pointer"
            [routerLink]="getAvatarRouterLink(application)"
            [src]="getAvatar(application)"
            [size]="getSizeOfAvatar()"
            [title]="
              isSchoolAdmin ? application.name : application.job.schoolName
            "
            [description]="
              isSchoolAdmin
                ? getUserJobFunctions()
                : application.job.schoolTitle
            "
            [rounded]="isSchoolAdmin ? true : false"></app-round-image>
          <div
            class="hidden h-full 3xl:flex flex-col justify-between items-end">
            <app-job-type-chip
              [type]="
                application.job.isFullTimeJob ? 'fulltime' : 'substitute'
              ">
            </app-job-type-chip>
            <app-passed-time-info [time]="application.createdOn" />
          </div>
        </div>

        <div class="p-5 rounded-xl text-[#001A41] bg-[#E1EDFF]">
          <div class="flex flex-col gap-5">
            <div class="flex items-center gap-2">
              <mat-icon class="material-symbols-rounded font-bold"
                >mail</mat-icon
              >
              <span class="text-sm xl:text-base">{{ application.email }}</span>
            </div>
            <div class="flex items-center gap-2">
              <mat-icon class="material-symbols-rounded font-bold"
                >call</mat-icon
              >
              <span class="text-sm xl:text-base">{{ application.phone }}</span>
            </div>
          </div>
        </div>

        <div class="flex gap-2 md:gap-4">
          @if (!isSchoolAdmin) {
            <a
              class="basis-[50%]"
              mat-flat-button
              color="primary"
              [routerLink]="['/schools', application.job.schoolId]">
              Schulprofil
            </a>
          }
          <a
            class="basis-[50%]"
            [routerLink]="['/jobs', application.job.id]"
            mat-stroked-button
            color="primary">
            Stellenbeschreibung
          </a>
          @if (isSchoolAdmin && application.status === 'PENDING') {
            <app-action-button
              class="basis-[50%]"
              (onClick)="reject(application)"
              customBgColor="#F9DEDC"
              customTextColor="#B00020"
              text="Absage schreiben"
              [loading]="loading" />
          }
        </div>
        <mat-divider></mat-divider>
      </div>
      <div>
        <h4 class="font-bold text-2xl xl:text-4xl">Nachricht</h4>
        <div
          class="text-ellipsis whitespace-pre-line text-sm w-full break-words">
          {{ application.message }}
        </div>
      </div>
      <div>
        <h4 class="font-bold text-2xl xl:text-4xl">Anhänge</h4>
        <div class="flex flex-col gap-6 text-sm xl:text-base">
          <div>
            <strong class="font-bold">Lebenslauf</strong>
            <ng-container
              *ngTemplateOutlet="
                fileTemplate;
                context: { $implicit: application.cv }
              "></ng-container>
          </div>
          @if (qualifications.length) {
            <div class="flex flex-col">
              <strong class="font-bold">Diplome und Zertifikate</strong>
              <div class="flex flex-col gap-3">
                @for (
                  qualification of qualifications;
                  track qualification.url
                ) {
                  <ng-container
                    *ngTemplateOutlet="
                      fileTemplate;
                      context: { $implicit: qualification }
                    "></ng-container>
                }
              </div>
            </div>
          }
          @if (application.additionalDocs?.length) {
            <div class="flex flex-col text-sm">
              <strong class="font-bold">Arbeitszeugnisse und Weiteres</strong>
              <div class="flex flex-col gap-3">
                @for (
                  additionalDoc of application.additionalDocs;
                  track additionalDoc.url
                ) {
                  <ng-container
                    *ngTemplateOutlet="
                      fileTemplate;
                      context: { $implicit: additionalDoc }
                    "></ng-container>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}

<ng-template #fileTemplate let-file>
  <div class="flex gap-8 justify-between items-center">
    <div class="w-full line-clamp-1 text-ellipsis">
      {{ file.description || file.filename }}
    </div>
    <button
      class="shrink-0"
      (click)="download(file)"
      mat-stroked-button
      color="primary">
      Anzeigen
    </button>
  </div>
</ng-template>
