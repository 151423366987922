import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import {
  getDownloadURL,
  ref,
  uploadBytes,
  Storage,
  deleteObject,
} from '@angular/fire/storage';
import { BucketFile } from '../../../../types/core';
import { School } from '../../../../types/school';
import { SchoolService } from '../../../../services/school.service';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
} from 'ngx-image-compress';

type PreviewMedia = { file?: Blob; displayUrl?: string };

@Component({
  selector: 'app-school-avatar-form',
  templateUrl: './school-avatar-form.component.html',
  styleUrls: ['./school-avatar-form.component.scss'],
})
export class SchoolAvatarFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;

  media: PreviewMedia = {};
  deleted = false;

  croppedImage?: Blob | undefined | null;
  transform: ImageTransform = { scale: 1, translateUnit: 'px' };
  dimension: { width: number; height: number } = { width: 600, height: 600 };
  loading = false;
  base64Image?: DataUrl = undefined;
  private factor: number = 3;
  readonly acceptTypes = ['.jpg', '.jpeg', '.png'];

  @ViewChild('fileUpload')
  avatar!: ElementRef;

  constructor(
    private storage: Storage,
    private schoolService: SchoolService,
    private router: Router,
    private imageCompress: NgxImageCompressService,
    route: ActivatedRoute
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);
  }

  ngOnInit(): void {
    this.school$.subscribe(school => {
      this.media = {
        displayUrl: school.avatar?.url || '',
      };
    });
  }

  async upload(file: Blob): Promise<BucketFile> {
    const filename = `avatar`;
    const path = `/schools/${this.id}/${filename}`;
    const storageRef = ref(this.storage, path);

    // upload file
    await uploadBytes(storageRef, file);
    const publicUrl = await getDownloadURL(storageRef);

    return {
      path,
      url: publicUrl,
      mimetype: file.type || '',
    };
  }

  clear() {
    this.deleted = true;
    this.media = {};
  }

  async remove() {
    const path = `/schools/${this.id}/avatar`;
    const storageRef = ref(this.storage, path);
    await deleteObject(storageRef);
  }

  async save() {
    let obs: Observable<void> = EMPTY;
    if (this.croppedImage) {
      let t = parseInt(localStorage.getItem('TICK') || '0');
      t = t + 1;
      localStorage.setItem('TICK', t.toString());
      const avatar = await this.upload(this.croppedImage);
      obs = this.schoolService.update(this.id, { avatar });
    } else if (!this.media.file) {
      await this.remove();
      obs = this.schoolService.update(this.id, { avatar: null });
    }

    obs.subscribe(_ => {
      this.router.navigate(['schools', this.id]);
    });
  }

  selectFile(event: Event): void {
    this.loading = true;
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = e => this.compressFile(e.target?.result as DataUrl);
      reader.readAsDataURL(file);
    }
  }

  compressFile(url: DataUrl): void {
    this.imageCompress
      .compressFile(
        url,
        DOC_ORIENTATION.Default,
        50,
        100,
        Math.ceil(this.dimension.width * this.factor),
        Math.ceil(this.dimension.height * this.factor)
      )
      .then(result => (this.base64Image = result));
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  getImageUrl() {
    return this.media.displayUrl
      ? this.media.displayUrl
      : 'assets/defaults/school_avatar.svg';
  }

  zoomOut() {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale! - 0.1,
    };
  }

  zoomIn() {
    this.transform = {
      ...this.transform,
      scale: this.transform.scale! + 0.1,
    };
  }

  reset(): void {
    this.croppedImage = null;
    this.avatar.nativeElement.value = '';
    this.transform.scale = 1;
    this.base64Image = undefined;
  }
}
