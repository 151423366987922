@if (isPreview && isOwner) {
  <div class="flex justify-end pb-3">
    <ng-container
      *ngTemplateOutlet="
        regularIconButton;
        context: {
          id: 'closePreview',
          icon: 'close',
          action: ctxActions.closePreview
        }
      ">
    </ng-container>
  </div>
} @else {
  <div class="flex justify-between items-center pb-3">
    <div>
      <a
        mat-button
        [disabled]="actionInProgress !== null"
        [routerLink]="getReturnUrl()"
        [queryParamsHandling]="isOwner ? undefined : 'preserve'">
        <mat-icon class="material-symbols-rounded">keyboard_backspace</mat-icon>
        {{ isOwner ? 'HR-Tool' : 'Alle Ergebnisse' }}
      </a>
    </div>
    @if (isOwner) {
      <div class="flex gap-2">
        @if (isDraftJob()) {
          <button
            class="h-10 w-10 md:w-fit md:px-4 gap-2 rounded-full md:rounded-3xl flex justify-center items-center text-[#ffffff]"
            [disabled]="actionInProgress !== null"
            (click)="executePublishing()"
            [ngClass]="{
              'bg-[#2881fe]': actionInProgress === null,
              'bg-[#93c0fe]': actionInProgress !== null
            }">
            @if (actionInProgress === 'publish') {
              <mat-spinner class="m-[5px] white-spinner" diameter="30" />
            } @else {
              <mat-icon
                class="material-symbols-rounded font-bold md:text-[18px] md:h-[18px] md:w-[18px]">
                publish
              </mat-icon>
            }
            <div class="hidden md:block font-bold">Veröffentlichen</div>
          </button>
        }

        @if (!isArchivedJob()) {
          <div class="md:hidden">
            <ng-container
              *ngTemplateOutlet="
                regularIconButton;
                context: {
                  id: 'preview',
                  icon: 'preview',
                  action: ctxActions.preview
                }
              ">
            </ng-container>
          </div>
          <div class="hidden md:block">
            <button
              mat-stroked-button
              class="flex gap-2 action-button"
              color="primary"
              (click)="executePreviewing()">
              <mat-icon
                class="material-symbols-rounded font-bold mr-0 ml-[2px]">
                preview
              </mat-icon>
              <span class="inline-block">Vorschau</span>
            </button>
          </div>
        }

        @if (isDraftJob()) {
          <ng-container
            *ngTemplateOutlet="
              regularIconButton;
              context: {
                id: 'discard',
                icon: 'delete',
                iconTitle: 'Verwerfen',
                action: ctxActions.discard
              }
            ">
          </ng-container>
        }

        @if (isPublicJob()) {
          <ng-container
            *ngTemplateOutlet="
              regularIconButton;
              context: {
                id: 'archive',
                icon: 'archive',
                iconTitle: 'Archivieren',
                action: ctxActions.archive
              }
            ">
          </ng-container>
        }
      </div>
    }
  </div>
}

<ng-template
  #regularIconButton
  let-id="id"
  let-icon="icon"
  let-iconTitle="iconTitle"
  let-action="action">
  <button
    class="h-10 w-10 rounded-full flex justify-center items-center"
    [disabled]="actionInProgress !== null"
    (click)="action()">
    @if (actionInProgress === id) {
      <mat-spinner class="m-[5px]" diameter="30"></mat-spinner>
    } @else {
      <mat-icon
        class="material-symbols-rounded font-bold"
        [ngClass]="{ 'bright-font': actionInProgress !== null }"
        [attr.title]="iconTitle">
        {{ icon }}
      </mat-icon>
    }
  </button>
</ng-template>
