@if (!showEmptyCard) {
  <strong class="font-bold text-4xl md:text-5xl">Deine Aufgaben</strong>
  @if (isEditable) {
    <app-edit-button class="pl-4" routerLink="edit/tasks" />
  }
  <app-rich-text-view
    class="pt-4"
    [html]="job.yourTasks_html"
    tailwindCssLineClamp="line-clamp-6" />
}

@if (showEmptyCard) {
  <app-empty-card
    title="Deine Aufgaben"
    subtitle="Das erwartet deine künftigen Mitarbeitenden."
    description="Gehe bei der Aufgabenbeschreibung ruhig ins Detail, da Bewerbende genau wissen möchten, was sie erwartet."
    link="edit/tasks" />
}
