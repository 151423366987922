<div class="grid grid-cols-12">
  <div class="flex flex-col gap-8 col-span-12 md:col-span-6 md:col-start-4">
    <h1 class="font-bold text-4xl">Werte</h1>
    <mat-form-field>
      <mat-label>Wert</mat-label>
      <input
        matInput
        [formControl]="preferenceControl"
        (keyup.enter)="add()"
        placeholder="Wähle aus oder schreibe ins Feld" />
      <button
        [disabled]="!preferenceControl.value"
        matSuffix
        mat-icon-button
        (click)="add()">
        <mat-icon [color]="iconColor" class="material-symbols-rounded">
          add
        </mat-icon>
      </button>
    </mat-form-field>

    <div class="flex flex-col gap-4">
      <span class="text-base font-bold">
        …oder lass dich von der Schnellauswahl inspirieren:
      </span>
      <ul class="flex flex-col gap-2">
        <li
          (click)="addNamed('Teamfähigkeit')"
          class="cursor-pointer hover:font-bold">
          Teamfähigkeit
        </li>
        <li
          (click)="addNamed('Flexibilität')"
          class="cursor-pointer hover:font-bold">
          Flexibilität
        </li>
        <li
          (click)="addNamed('Optimismus')"
          class="cursor-pointer hover:font-bold">
          Optimismus
        </li>
        <li
          (click)="addNamed('Belastbarkeit')"
          class="cursor-pointer hover:font-bold">
          Belastbarkeit
        </li>
        <li
          (click)="addNamed('Organisationskompetenz')"
          class="cursor-pointer hover:font-bold">
          Organisationskompetenz
        </li>
        <li
          (click)="addNamed('Kreativität')"
          class="cursor-pointer hover:font-bold">
          Kreativität
        </li>
        <li
          (click)="addNamed('Analystisches Denken')"
          class="cursor-pointer hover:font-bold">
          Analystisches Denken
        </li>
        <li
          (click)="addNamed('Innovationsfreude')"
          class="cursor-pointer hover:font-bold">
          Innovationsfreude
        </li>
      </ul>
    </div>

    <div class="flex flex-col gap-4">
      <span class="font-bold text-base">Deine Auswahl</span>
      <div class="flex flex-wrap gap-4">
        @for (preference of preferences; track preference) {
          <mat-chip-row (removed)="remove(preference)" class="optimize">
            {{ preference }}
            <button matChipRemove>
              <mat-icon class="material-symbols-rounded">cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
      </div>
    </div>

    <div class="flex justify-end gap-8">
      <a mat-button color="primary" [routerLink]="['/me', id]">Abbrechen</a>
      <button mat-flat-button color="primary" type="submit" (click)="save()">
        Speichern
      </button>
    </div>
  </div>
</div>
