import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable, Subscriber } from 'rxjs';
import { School } from '../../../../types/school';
import { BucketFile } from '../../../../types/core';
import { SchoolService } from '../../../../services/school.service';
import { FormControl } from '@angular/forms';
import { FileService } from '../../../../services/files.service';
import { deepCopy, scaleDataURL } from '../../../../core/helpers';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export type Media = {
  title: FormControl<string | null>;
  description: FormControl<string | null>;
  media?: PreviewMedia;
};
export type PreviewMedia = {
  bucket?: BucketFile;
  file?: Blob;
  displayUrl?: string | null | undefined;
  filename?: string | undefined;
  type: MediaType;
  coverImage?: CoverImage;
};
export type CoverImage = {
  file?: Blob;
  displayUrl: string | null | undefined;
  bucket?: BucketFile;
};
export type MediaType = 'image' | 'audio';

@Component({
  selector: 'app-school-media-form',
  templateUrl: './school-media-form.component.html',
  styleUrls: ['./school-media-form.component.scss'],
})
export class SchoolMediaFormComponent implements OnInit {
  id: string;
  school$: Observable<School>;
  school?: School;
  loading = false;
  upload = new FormControl<string | undefined>(undefined);
  edit: boolean = false;
  mediaFiles: Media[] = [];
  keepSorting: Media[] = [];
  deletableMediaFiles: Media[] = [];
  readonly max = 50;
  readonly MAX_HEIGHT = 1600;
  readonly MAX_WIDTH = 1600;
  readonly imageExtensions = ['.jpg', '.jpeg', '.png', '.tiff'];
  readonly audioExtensions = ['.mp3'];
  readonly acceptTypes = [...this.imageExtensions, ...this.audioExtensions];

  constructor(
    route: ActivatedRoute,
    private schoolService: SchoolService,
    private fileService: FileService,
    private router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id') as string;
    this.school$ = schoolService.getById(this.id);
  }

  ngOnInit() {
    this.school$.subscribe(school => {
      this.school = school;
      this.mediaFiles = (school.media ?? []).map(this.bucketFile2Media);
    });
  }

  getExtension(filename: string) {
    const i = filename.lastIndexOf('.');
    return filename.substring(i);
  }

  getType(file?: File): MediaType | undefined {
    if (!file) return 'image';

    const extension = this.getExtension(file.name);
    if (this.imageExtensions.some(e => extension === e)) return 'image';
    if (this.audioExtensions.some(e => extension === e)) return 'audio';

    return 'image';
  }

  async uploadMediaFiles(mediaFiles: Media[]) {
    const bucketFiles: BucketFile[] = [];

    for (const file of mediaFiles) {
      if (file.media?.file && !file.media.bucket) {
        const path = `/schools/${this.id}/media`;

        if (file.media.type !== 'audio' && file.media.displayUrl) {
          file.media.file = await scaleDataURL(
            file.media.displayUrl,
            this.MAX_HEIGHT,
            this.MAX_WIDTH
          );
        }

        const bucketFile = await this.fileService.uploadAsync(
          path,
          file.media.file,
          file.media.filename,
          true,
          file.description.value ?? undefined
        );

        if (file.media.type === 'audio' && file.media.coverImage?.file) {
          bucketFile.coverImage = await this.fileService.uploadAsync(
            path,
            file.media.coverImage.file,
            `${bucketFile.filename}_cover`,
            true
          );
        }

        bucketFiles.push({
          ...bucketFile,
          title: file.title.value ?? '',
          description: file.description.value ?? '',
          type: file.media.type,
        });
      } else if (file.media?.bucket) {
        bucketFiles.push({
          ...file.media.bucket,
          title: file.title.value ?? '',
          description: file.description.value ?? undefined,
        });
      }
    }

    return bucketFiles;
  }

  async save() {
    this.loading = true;
    try {
      const bucketFiles = await this.uploadMediaFiles(this.mediaFiles);

      for (const file of this.deletableMediaFiles) {
        if (file.media?.bucket?.path) {
          await this.fileService.remove(file.media.bucket.path);
        }
        if (file.media?.coverImage?.bucket?.path) {
          await this.fileService.remove(file.media.coverImage.bucket.path);
        }
      }

      await firstValueFrom(
        this.schoolService.update(this.id, { media: bucketFiles })
      );

      await this.router.navigate(['schools', this.id]);
    } catch (error) {
      console.error('Error while uploading school impressions', error);
    } finally {
      this.loading = false;
    }
  }

  toggleMode(abort?: boolean): void {
    if (!abort) this.keepSorting = deepCopy<Media>(this.mediaFiles);
    this.edit = !this.edit;
    if (abort && !!this.keepSorting.length) {
      this.mediaFiles = deepCopy<Media>(this.keepSorting);
      this.keepSorting = [];
    }
  }

  async selectFiles(event: Event): Promise<void> {
    this.upload.setErrors(null);
    const files = (event.target as HTMLInputElement).files ?? [];

    if (this.mediaFiles.length + files.length > this.max) {
      this.upload.setErrors({ maxCount: true });
      return;
    }

    for (const file of files) {
      const media = await firstValueFrom(this.loadMedia(file));
      if (media) this.mediaFiles.push(media);
    }
  }

  async selectCover(event: Event, index: number): Promise<void> {
    const files = (event.target as HTMLInputElement).files ?? [];
    const file = files[0];
    const media = await firstValueFrom(this.loadMedia(file));

    if (media) {
      const current = this.mediaFiles[index];
      this.mediaFiles[index] = {
        ...current,
        media: {
          ...current.media,
          type: 'audio',
          coverImage: { file, displayUrl: media.media?.displayUrl },
        },
      };
    }
  }

  deleteMedia(index: number): void {
    const doc = this.mediaFiles[index];
    this.deletableMediaFiles.push(doc);
    this.mediaFiles.splice(index, 1);
    this.upload.setErrors(null);
  }

  sortMediaFiles(event: CdkDragDrop<number>): void {
    moveItemInArray(this.mediaFiles, event.previousIndex, event.currentIndex);
  }

  mediaFilesValid(): boolean {
    return (
      this.upload.valid &&
      this.mediaFiles.every(file => {
        return file.media?.type === 'audio'
          ? !!file.media?.coverImage
          : file.title.valid;
      })
    );
  }

  private bucketFile2Media(file: BucketFile): Media {
    const coverImage: CoverImage = {
      displayUrl: file.coverImage?.url,
      bucket: file.coverImage,
    };

    return {
      title: new FormControl<string>(file.title || '', []),
      description: new FormControl<string>(file.description || '', []),
      media: {
        coverImage,
        bucket: file,
        displayUrl: file.url,
        filename: file.filename,
        type: file.type as MediaType,
      },
    };
  }

  private loadMedia(file: File): Observable<Media | undefined> {
    const reader = new FileReader();

    return new Observable((observer: Subscriber<Media | undefined>) => {
      if (file) {
        reader.onloadend = event =>
          observer.next({
            title: new FormControl<string>('', []),
            description: new FormControl<string>('', []),
            media: {
              displayUrl: event.target?.result as string,
              file: file,
              filename: file.name,
              type: this.getType(file) || 'image',
            },
          });
        reader.readAsDataURL(file);
      } else {
        observer.error('No Media file found');
      }
    });
  }
}
