@if (!showEmptyCard) {
  <strong class="font-bold text-4xl md:text-5xl">Berufserfahrung</strong>
  @if (isOwner) {
    <app-add-button
      class="pl-4"
      routerLink="edit/experience/new"></app-add-button>
  }
  <div class="flex flex-col pt-4 gap-8">
    @for (
      workExperience of workeExperiences! | slice: 0 : amount;
      track workExperience.id;
      let i = $index
    ) {
      <div class="flex flex-row gap-8">
        <div
          [ngClass]="{ 'primary-bg': i === 0 }"
          class="w-[3px] rounded-full"></div>
        <div class="flex flex-col">
          <strong
            class="text-[0.625rem] font-very-bright-color tracking-widest font-bold">
            {{ getStartDate(workExperience) }}
            -
            {{ getEndDate(workExperience) }}
          </strong>
          <div class="min-h-12 flex">
            <strong class="text-base self-center font-bold">
              {{ workExperience.jobTitle }}
            </strong>
            @if (isOwner) {
              <app-edit-button
                [background]="false"
                class="pl-2"
                [routerLink]="
                  'edit/experience/' + workExperience.id
                "></app-edit-button>
            }
          </div>
          @if (workExperience.schoolLevels) {
            <span class="text-sm">
              {{ workExperience.schoolLevels.join(' | ') }}
            </span>
          }
          @if (workExperience.subjects) {
            <span class="text-sm pt-2">
              {{ workExperience.subjects.join(', ') }}
            </span>
          }
          <div class="flex flex-col pt-2 pb-2">
            <span class="text-sm font-very-bright-color">
              {{ workExperience.employer }}
            </span>
            <span class="text-sm font-very-bright-color">
              {{ workExperience.employmentType }}
              @if (workExperience.employmnentRateInPercantage) {
                <span>
                  - Pensum
                  {{ workExperience.employmnentRateInPercantage }} %
                </span>
              }
            </span>
            <span class="text-sm font-very-bright-color">
              {{ workExperience.location }}
            </span>
          </div>
          <span class="text-sm">{{ workExperience.description }}</span>
        </div>
      </div>
    }
    <div class="flex justify-start">
      @if (showAll && profile.workExperiences!.length > 3) {
        <button
          mat-stroked-button
          color="primary"
          class="mdc-button--light mdc-button--grey"
          (click)="toggleShowAll()">
          <mat-icon class="material-symbols-rounded scale-125">
            expand_less
          </mat-icon>
          Weniger anzeigen
        </button>
      }
      @if (!showAll && profile.workExperiences!.length > 3) {
        <button
          mat-stroked-button
          color="primary"
          class="mdc-button--light mdc-button--grey"
          (click)="toggleShowAll()">
          <mat-icon class="material-symbols-rounded scale-125">
            expand_more
          </mat-icon>
          Weiterlesen
        </button>
      }
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Berufserfahrung"
    subtitle="Und was machst du so?"
    description="Hier kannst du die Stationen deiner Karriere und deine bisher gesammelte berufliche Erfahrung darstellen."
    link="edit/experience/new"></app-empty-card>
}
