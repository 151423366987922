import { inject, Injectable } from '@angular/core';
import { UploadProgressEvent } from '@api.video/video-uploader';
import { ApiVideoService } from './api-video.service';
import { ApiVideoAsset } from '../types/core';
import { firstValueFrom, Observable } from 'rxjs';

import { JobService } from './job.service';
import { SchoolService } from './school.service';
import { ConfirmDialogProps } from '../components/confirm-dialog/confirm-dialog.component';

export enum MediaVideoServiceActions {
  SCHOOL = 'SCHOOL',
  JOB = 'JOB',
}
type Action = MediaVideoServiceActions.JOB | MediaVideoServiceActions.SCHOOL;

@Injectable({
  providedIn: 'root',
})
export class MediaVideoService {
  readonly acceptTypes: string[] = ['.avi', '.webm', '.mp4', '.wmv', '.mov'];
  readonly deleting: string = 'wird gelöscht...';
  readonly data: ConfirmDialogProps = {
    text: 'Willst Du das Video wirklich löschen?',
    cancelText: 'Abbrechen',
    confirmText: 'Löschen',
  };

  readonly apiVideoService = inject(ApiVideoService);
  readonly jobService = inject(JobService);
  readonly schoolService = inject(SchoolService);

  uploadProgress$: Observable<UploadProgressEvent> =
    this.apiVideoService.uploadProgress$;

  getProgress(progress: UploadProgressEvent) {
    const p = Math.round((progress.uploadedBytes / progress.totalBytes) * 100);
    return p === 100 ? 'wird verarbeitet...' : `${p || 0} %`;
  }

  async upload(
    event: Event,
    id: string,
    action: Action = MediaVideoServiceActions.SCHOOL
  ): Promise<ApiVideoAsset | null> {
    try {
      const video = await this.uploadVideoAsset(event);

      if (video) {
        action === MediaVideoServiceActions.SCHOOL
          ? await this.schoolService.updateAsync(id, { video })
          : await firstValueFrom(this.jobService.update(id, { video }));
      }

      return video;
    } catch (e) {
      console.error('Error while uploading video', e);
    }

    return null;
  }

  async remove(
    vid: string,
    id: string,
    action: Action = MediaVideoServiceActions.SCHOOL
  ): Promise<void> {
    try {
      const video = null;
      await this.removeVideoAsset(vid, id, action);
      action === MediaVideoServiceActions.SCHOOL
        ? await this.schoolService.updateAsync(id, { video })
        : await firstValueFrom(this.jobService.update(id, { video }));

      return;
    } catch (e) {
      console.error('Error while removing video', e);
    }
  }

  private async uploadVideoAsset(event: Event): Promise<ApiVideoAsset | null> {
    const target = event.target as HTMLInputElement;
    const files: FileList | null = target.files;

    if (files && files.length === 1) {
      const response = await this.apiVideoService.uploadAsync(files[0]);
      return {
        id: response.videoId,
        hls: response.assets?.hls ?? '',
        mp4: response.assets?.mp4 ?? '',
      };
    }

    return null;
  }

  private async removeVideoAsset(
    vid: string,
    id: string,
    action: Action = MediaVideoServiceActions.SCHOOL
  ): Promise<void> {
    return firstValueFrom(
      action === MediaVideoServiceActions.SCHOOL
        ? this.apiVideoService.remove(vid, id)
        : this.apiVideoService.removeJobVideo(vid, id)
    );
  }
}
