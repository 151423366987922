<div class="flex justify-center">
  <div class="w-full max-w-[1120px]">
    @if (!isCurrentUser) {
      <div class="pb-4">
        <a mat-button [routerLink]="['..']" queryParamsHandling="preserve">
          <mat-icon class="material-symbols-rounded"
            >keyboard_backspace</mat-icon
          >
          Alle Ergebnisse
        </a>
      </div>
    }

    @if (profile$ | async; as profile) {
      <div class="flex flex-col gap-24">
        <app-profile-header
          [contact]="contact"
          [isOwner]="isCurrentUser"
          [profile]="profile" />

        <div class="grid grid-cols-12">
          <div
            class="col-span-12 lg:col-start-3 lg:col-span-8 flex flex-col gap-24">
            @if (isCurrentUser) {
              <app-profile-progress-card
                class="conditional-hide"
                [profile]="profile" />
            }

            <app-profile-preferences-card
              class="conditional-hide"
              [profile]="profile"
              [isOwner]="isCurrentUser" />

            <app-profile-about-me-card
              class="conditional-hide"
              [profile]="profile"
              [isOwner]="isCurrentUser" />

            <app-profile-qualification-card
              id="qualifications"
              class="conditional-hide"
              [profile]="profile"
              [isOwner]="isCurrentUser" />

            <app-profile-work-experiencs-card
              class="conditional-hide"
              [profile]="profile"
              [isOwner]="isCurrentUser" />

            <app-profile-education-card
              class="conditional-hide"
              [profile]="profile"
              [isOwner]="isCurrentUser" />

            <app-profile-language-card
              class="conditional-hide"
              [profile]="profile"
              [isOwner]="isCurrentUser" />
          </div>
        </div>
      </div>
    }
  </div>
</div>
