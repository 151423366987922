import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { JobService } from '../../../services/job.service';
import { PermissionsService } from '../../../services/permissions.service';
import { Job } from '../../../types/job';
import { PublicToolbarService } from '../../../services/public-toolbar.service';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
})
export class JobDetailComponent {
  id?: string | null;
  job$: Observable<Job> = EMPTY;
  fetch$ = new ReplaySubject(1);

  isOwner = false;
  isPreview = false;

  constructor(
    route: ActivatedRoute,
    private permissionsService: PermissionsService,
    public jobService: JobService,
    toolbarService: PublicToolbarService
  ) {
    toolbarService.set({ showBoth: true });

    route.paramMap.subscribe(params => {
      this.id = params.get('id');

      this.job$ = this.fetch$.pipe(
        switchMap(() =>
          jobService.getById(this.id!).pipe(
            tap(job => {
              this.isOwner = this.permissionsService.isOwner(job.schoolRootId);
            })
          )
        )
      );

      this.reload();
    });

    route.queryParamMap.subscribe(params => {
      this.isPreview = params.get('preview') != null;
    });
  }

  isEditable(job: Job) {
    return this.isOwner && !job.isClosed && !this.isPreview;
  }

  reload() {
    this.fetch$.next(1);
  }

  shouldRenderChild(childHasData: boolean, job: Job) {
    return childHasData || this.isEditable(job);
  }
}
