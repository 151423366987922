@if (!showEmptyCard) {
  <div class="flex flex-col gap-4">
    <div clasS="flex gap-4 justify-between">
      <strong class="font-bold text-2xl md:text-5xl">Impressionen</strong>
      @if (isOwner) {
        <app-edit-button routerLink="edit/media"></app-edit-button>
      }
    </div>
    <div class="flex flex-col w-full">
      <div class="col-span-2 w-full aspect-video overflow-hidden rounded-2xl">
        @for (item of school.media; track item.url; let i = $index) {
          <div
            class="flex w-full aspect-video justify-center relative"
            [ngClass]="{ hidden: i !== activeIndex }">
            @if (item.type === 'image') {
              <div
                class="w-full h-full aspect-video flex justify-center relative bgfill">
                <img class="z-20" [src]="item.url" [alt]="item.title" />
                <div
                  class="z-10 h-full w-full absolute bg-no-repeat bg-cover bg-center brightness-[0.3]"></div>
              </div>
            }
            @if (item.type === 'audio' && i === activeIndex) {
              <app-video-player
                [src]="item.url!"
                [cover]="item.coverImage!.url!"
                class="w-full"
                [type]="item.mimetype!"></app-video-player>
            }
            <div
              class="flex justify-center absolute left-0 h-full w-24 z-[1000]">
              <button
                (click)="prev()"
                [disabled]="prevButtonDisabled"
                mat-icon-button
                class="scale-[0.85] self-center fab-button-color">
                <mat-icon class="material-symbols-rounded scale-[1.3] pr-[1px]">
                  navigate_before
                </mat-icon>
              </button>
            </div>
            <div
              class="flex justify-center absolute right-0 h-full w-24 z-[1000]">
              <button
                (click)="next()"
                [disabled]="nextButtonDisabled"
                mat-icon-button
                class="scale-[0.85] self-center fab-button-color">
                <mat-icon class="material-symbols-rounded scale-[1.3] pl-[1px]">
                  navigate_next
                </mat-icon>
              </button>
            </div>
          </div>
        }
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex justify-center pt-4">
          <div class="flex gap-1 pl-1">
            @for (item of school.media; track item.url; let i = $index) {
              <span
                [ngClass]="{ active: i === activeIndex }"
                class="flex w-2 h-2 indicator rounded-full"></span>
            }
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <strong class="text-base font-bold">{{ getTitle() }}</strong>
          @if (getDescription()) {
            <p class="text-base">
              {{ getDescription() }}
            </p>
          }
        </div>
      </div>
    </div>
  </div>
}

@if (showEmptyCard && isOwner) {
  <app-empty-card
    title="Impressionen"
    subtitle="Verleih deinem Schulprofil Farbe."
    description="Mit ansprechenden Bildern erhöhst du die Attraktivität deiner Schule und machst sie für Aussenstehende fassbar."
    link="edit/media"></app-empty-card>
}
